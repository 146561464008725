import { useQuery } from "@tanstack/react-query";
import { createContext } from "react";
import { getProvidersList } from "../config/api";
import useAuth from "../hooks/useAuth";

const ProvidersContext = createContext({
  providers: null,
  providersIsLoading: true,
});

export const ProvidersProvider = ({ children }) => {
  const { token } = useAuth();
  const { data, isLoading } = useQuery({
    enabled: !!token,
    staleTime: Infinity,
    queryKey: ["all", "providers"],
    queryFn: getProvidersList,
  });

  const value = {
    providers: data?.data,
    providersIsLoading: isLoading,
  };
  return (
    <ProvidersContext.Provider value={value}>
      {children}
    </ProvidersContext.Provider>
  );
};

export default ProvidersContext;
