import { CircularProgress, Stack } from "@mui/material";

export default function Loader() {
  return (
    <Stack
      width="100%"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size={60} />
    </Stack>
  );
}
