import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { MdVisibilityOff, MdVisibility } from "react-icons/md";

export default function PasswordField(props) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      {...props}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" onClick={togglePassword}>
              {showPassword ? (
                <MdVisibility color="grey" />
              ) : (
                <MdVisibilityOff color="grey" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
