import { LoadingButton } from "@mui/lab";
import { Dialog as MuiDialog, DialogContent } from "@mui/material";
import { useState } from "react";

export default function Dialog({
  children,
  dialogContent,
  btnProps,
  dialogMaxWidth,
  loading,
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <LoadingButton loading={loading} {...btnProps} onClick={handleOpen}>
        {children}
      </LoadingButton>
      <MuiDialog
        open={open}
        onClose={handleClose}
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 2,
            maxWidth: dialogMaxWidth ?? "950px",
          },
        }}
      >
        <DialogContent>
          {{ ...dialogContent, props: { ...dialogContent.props, handleClose } }}
        </DialogContent>
      </MuiDialog>
    </>
  );
}
