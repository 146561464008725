import {
  AppBar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  Drawer as MuiDrawer,
  Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { FiLogOut } from "react-icons/fi";
import { BsFillPersonFill } from "react-icons/bs";
import { ReactComponent as CalendarIcon } from "../../assets/icons/edit-calendar.svg";
import src from "../../assets/images/logo.png";
import { path } from "../../config/routes";
import { VscMenu } from "react-icons/vsc";
import { useState } from "react";

export const drawerWidth = 270;

export default function Drawer() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { logout } = useAuth();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const closeDrawer = () => {
    setMobileOpen(false);
  };

  const drawer = (
    <Stack alignItems="center" height="100%" py={5}>
      <Stack alignItems="center" display={{ xs: "none", sm: "flex" }}>
        <Box component="img" src={src} width="75%" />
        <Typography
          variant="h4"
          textAlign="center"
          color="white"
          letterSpacing="0.1em"
        >
          EVENTS <br /> BOOKING
        </Typography>
      </Stack>
      <List sx={{ mt: 8, height: "100%" }}>
        <Stack height="100%" justifyContent="space-between">
          <div>
            <Link
              onClick={closeDrawer}
              component={RouterLink}
              color="text.primary"
              to={path.events}
              underline="none"
            >
              <ListItem key="events" disablePadding>
                <ListItemButton>
                  <ListItemIcon
                    sx={{
                      fontSize: "1.5rem",
                      color: "white",
                    }}
                  >
                    {<CalendarIcon />}
                  </ListItemIcon>
                  {
                    <ListItemText
                      primary={"Events"}
                      primaryTypographyProps={{
                        fontSize: 20,
                        fontWeight: 400,
                        color: (theme) => {
                          return { sm: "white" };
                        },
                      }}
                    />
                  }
                </ListItemButton>
              </ListItem>
            </Link>

            <Link
              onClick={closeDrawer}
              component={RouterLink}
              color="text.primary"
              to={path.providers}
              underline="none"
            >
              <ListItem key="events" disablePadding>
                <ListItemButton>
                  <ListItemIcon
                    sx={{
                      fontSize: "1.5rem",
                      color: "white",
                    }}
                  >
                    {<BsFillPersonFill size={40} />}
                  </ListItemIcon>
                  {
                    <ListItemText
                      primary={"Providers"}
                      primaryTypographyProps={{
                        fontSize: 20,
                        fontWeight: 400,
                        color: (theme) => {
                          return { sm: "white" };
                        },
                      }}
                    />
                  }
                </ListItemButton>
              </ListItem>
            </Link>
          </div>

          <ListItem key="logout" disablePadding onClick={logout}>
            <ListItemButton>
              <ListItemIcon
                sx={{
                  fontSize: "1.5rem",
                  color: "white",
                }}
              >
                {<FiLogOut size={30} />}
              </ListItemIcon>
              {
                <ListItemText
                  primary={"Logout"}
                  primaryTypographyProps={{
                    fontSize: 20,
                    fontWeight: 400,
                    color: (theme) => {
                      return { sm: "white" };
                    },
                  }}
                />
              }
            </ListItemButton>
          </ListItem>
        </Stack>
      </List>
    </Stack>
  );

  return (
    <Stack>
      <AppBar
        position="static"
        sx={{
          display: { xs: "block", sm: "none" },
        }}
      >
        <Toolbar
          component={Stack}
          flexDirection="row"
          // justifyContent="space-between"
        >
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <VscMenu color="white" />
          </IconButton>
          <Box component="img" src={src} width="3rem" />
        </Toolbar>
      </AppBar>
      <Box component="nav" width={drawerWidth} flexShrink={{ sm: 0 }}>
        <MuiDrawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </MuiDrawer>
        <MuiDrawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderTopRightRadius: "40px",
              backgroundColor: "primary.main",
            },
          }}
          open
        >
          {drawer}
        </MuiDrawer>
      </Box>
    </Stack>
  );
}
