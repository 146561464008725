import {
  IconButton,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";

import { IoCloseOutline } from "react-icons/io5";

export default function TicketDetails({
  formik,
  index,
  planOptions,
  editMode,
}) {
  const removeTicketType = () => {
    const newTickets = formik.values.ticket.filter(
      (ticket) => ticket.id !== formik.values.ticket[index].id
    );
    formik.setFieldValue("ticket", newTickets);
  };

  const planTickets = !!planOptions?.length
    ? planOptions.filter((plan) => {
        return plan?.id === formik.values.eventPlan;
      })[0]?.ticketOptions
    : [];

  const handleTicketChange = (e) => {
    formik.handleChange(e);
    let newTickets = formik.values.ticket;
    const selectedTicketType = planTickets?.filter((plan) => {
      return plan?.id === e?.target?.value;
    })[0];
    newTickets[index] = {
      ...newTickets[index],
      unitPrice: selectedTicketType?.price,
      quantity: 1000,
    };
  };

  return (
    <Stack
      flexDirection="row"
      // justifyContent="space-between"
      alignItems="flex-start"
      mt={index !== 0 ? 1 : 0}
    >
      <Stack
        name={`ticket.${index}`}
        flexDirection="row"
        gap={{ xs: 1, sm: 2 }}
      >
        <Stack gap={0.5}>
          <InputLabel>Ticket Type</InputLabel>
          <TextField
            id={`ticket.${index}.ticketType`}
            name={`ticket.${index}.ticketType`}
            variant="outlined"
            size="small"
            select
            disabled={!!!planOptions?.length || editMode}
            value={formik.values.ticket[index].ticketType}
            onChange={handleTicketChange}
            onBlur={formik.handleBlur}
            error={
              !!formik.touched.ticket &&
              !!formik.touched.ticket[index]?.ticketType &&
              !!formik.errors.ticket &&
              !!formik.errors.ticket[index]?.ticketType
            }
            helperText={
              !!formik.touched.ticket &&
              !!formik.touched.ticket[index]?.ticketType &&
              !!formik.errors.ticket &&
              formik.errors.ticket[index]?.ticketType
            }
          >
            {!!planOptions?.length ? (
              !!planTickets?.length ? (
                planTickets?.map((option) => (
                  <MenuItem key={option?.id} value={option?.id}>
                    {option?.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={0} value={0} disabled>
                  No Ticket Options
                </MenuItem>
              )
            ) : (
              <MenuItem key={0} value={0} disabled></MenuItem>
            )}
          </TextField>
        </Stack>

        <Stack gap={0.5}>
          <InputLabel>Quantity</InputLabel>
          <TextField
            id={`ticket.${index}.quantity`}
            name={`ticket.${index}.quantity`}
            variant="outlined"
            size="small"
            type="number"
            value={formik.values.ticket[index].quantity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              !!formik.touched.ticket &&
              !!formik.touched?.ticket[index]?.quantity &&
              !!formik.errors.ticket &&
              !!formik.errors.ticket[index]?.quantity
            }
            helperText={
              !!formik.touched.ticket &&
              !!formik.touched.ticket[index]?.quantity &&
              !!formik.errors.ticket &&
              formik.errors.ticket[index]?.quantity
            }
          />
        </Stack>

        <Stack gap={0.5}>
          <InputLabel>Unit Price</InputLabel>
          <TextField
            id={`ticket.${index}.unitPrice`}
            name={`ticket.${index}.unitPrice`}
            variant="outlined"
            size="small"
            type="number"
            onChange={formik.handleChange}
            value={formik.values.ticket[index].unitPrice}
            onBlur={formik.handleBlur}
            error={
              !!formik.touched.ticket &&
              !!formik.touched.ticket[index]?.unitPrice &&
              !!formik.errors.ticket &&
              !!formik.errors.ticket[index]?.unitPrice
            }
            helperText={
              !!formik.touched.ticket &&
              !!formik.touched.ticket[index]?.unitPrice &&
              !!formik.errors.ticket &&
              formik.errors.ticket[index]?.unitPrice
            }
            InputProps={{
              endAdornment: "SDG",
            }}
          />
        </Stack>
      </Stack>

      {formik.values.ticket?.length > 1 && !editMode && (
        <IconButton onClick={removeTicketType}>
          <IoCloseOutline color="grey" />
        </IconButton>
      )}
    </Stack>
  );
}
