import { Box, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useAuth from "../hooks/useAuth";
import { login as loginApi } from "../config/api";
import { path } from "../config/routes";
import src from "../assets/images/background.jpg";
import PasswordField from "../components/PasswordField";
import { useMutation } from "@tanstack/react-query";

const errorMsg = {
  emailReq: "Email is required",
  emailInvalid: "Please provide a valid E-mail",
  passwordReq: "Password is required",
};

const validationSchema = yup.object({
  email: yup.string().required(errorMsg.emailReq),
  password: yup.string().required(errorMsg.passwordReq),
});

export default function Login() {
  const { saveToken } = useAuth();
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation({
    mutationFn: loginApi,
    onSuccess: (data) => {
      saveToken(data?.data?.accessToken);
      navigate(path.events);
    },
  });

  const login = async ({ email, password }) => {
    mutate({
      username: email,
      password,
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: login,
  });

  return (
    <Stack
      minHeight="100vh"
      minWidth="100vw"
      justifyContent="center"
      alignItems="center"
      bgcolor={{ xs: "white", sm: "background.main" }}
    >
      <Stack
        component="form"
        onSubmit={formik.handleSubmit}
        bgcolor="white"
        flexDirection="row"
        width={{ xs: "100%", sm: "75%", md: "65%" }}
        borderRadius={{ xs: 0, sm: 5 }}
        boxShadow={{ xs: 0, sm: 3 }}
      >
        <Box
          component="img"
          src={src}
          width="40%"
          minHeight="100%"
          borderRadius={5}
          display={{ xs: "none", md: "inline" }}
          sx={{ objectFit: "cover" }}
          boxShadow={3}
        />
        <Stack
          width={{ xs: "100%", md: "60%" }}
          p={{ xs: 5, md: 7 }}
          boxSizing="border-box"
          spacing={3}
          alignItems="flex-start"
        >
          <div>
            <Typography variant="h3" fontWeight={500}>
              Welcome back!
            </Typography>
            <Typography mb={{ xs: 1, md: 2 }} variant="h5">
              Please login to continue
            </Typography>
          </div>
          <TextField
            id="email"
            name="email"
            variant="outlined"
            placeholder="Email"
            fullWidth
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <PasswordField
            id="password"
            name="password"
            variant="outlined"
            placeholder="password"
            fullWidth
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <LoadingButton
            type="submit"
            loading={isLoading}
            variant="contained"
            sx={{ py: 1, px: 3 }}
          >
            Submit
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
}
