import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { IoAddOutline } from "react-icons/io5";
import * as yup from "yup";
import { errorMsg } from "../../../config/util";
import PlanTicketType from "../PlanTicketType";
import useProvidersList from "../../../hooks/useProvidersList";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getPlan, postPlan, updatePlan } from "../../../config/api";
import { toast } from "react-toastify";

const validationSchema = yup.object({
  name: yup.string().required(errorMsg.required),
  provider: yup.string().required(errorMsg.required),
  tickets: yup.array().of(
    yup.object().shape({
      ticketType: yup.string().required(errorMsg.required),
      description: yup.string(),
      price: yup.number().required(errorMsg.required).min(1, errorMsg.invalid),
    })
  ),
});

export default function AddEventPlan({ handleClose, planId }) {
  const { providers, providersIsLoading } = useProvidersList();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: !!planId ? updatePlan : postPlan,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["providers", "details"],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: ["plans"],
        exact: true,
      });
      handleClose();
      toast.success(
        `The plan has been successfully ${!!planId ? "editted" : "added"}`
      );
    },
  });

  const onSubmit = async ({ name, provider, tickets }) => {
    let data = {
      name,
      providerId: provider,
      ticketOptions: tickets?.map((ticket) => {
        let ticketObj = {
          name: ticket?.ticketType,
          price: ticket?.price,
          description: ticket?.description,
        };
        if (!!planId) {
          ticketObj.id = ticket?.backendId;
        }
        return ticketObj;
      }),
    };
    if (!!planId) data = { body: data, id: planId };

    mutate(data);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      provider: "",
      tickets: [
        { ticketType: "", description: "", price: "", id: crypto.randomUUID() },
      ],
    },
    validationSchema,
    onSubmit,
  });

  const {
    isLoading: loadingDetails,
    isError,
    error,
  } = useQuery({
    cacheTime: 0,
    staleTime: Infinity,
    enabled: !!planId,
    queryKey: ["plan", planId],
    queryFn: getPlan.bind(this, planId),
    onSuccess: (data) => {
      const values = {
        ...data?.data,
        provider: data?.data?.provider_id,
        tickets: data?.data?.ticketOptions?.map((ticket) => {
          return { ...ticket, ticketType: ticket?.name, backendId: ticket?.id };
        }),
      };
      formik.setValues(values);
    },
  });

  if (!!planId && loadingDetails) {
    return <CircularProgress sx={{ my: 5, display: "block", mx: "auto" }} />;
  }
  if (!!planId && isError)
    return (
      <>
        <Typography mt={5} variant="h5">
          Error fetching data
        </Typography>
        <Typography mt={1} mb={5}>
          {error?.response?.data?.message}
        </Typography>
      </>
    );

  const addTicketType = () => {
    const newTickets = formik.values.tickets.concat({
      ticketType: "",
      description: "",
      price: "",
      id: crypto.randomUUID(),
    });

    formik.setFieldValue("tickets", newTickets);
  };

  return (
    <Stack component="form" onSubmit={formik.handleSubmit} mt={8} gap={3}>
      <Stack flexDirection={{ sm: "row" }} gap={{ xs: 2, sm: 5, md: 10 }}>
        <Stack gap={0.5} flexGrow={1}>
          <InputLabel>Plan Name</InputLabel>
          <TextField
            id="name"
            name="name"
            variant="outlined"
            size="small"
            fullWidth
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Stack>

        <Stack gap={0.5} flexGrow={1}>
          <InputLabel>Provider</InputLabel>
          <TextField
            id="provider"
            name="provider"
            variant="outlined"
            size="small"
            select
            fullWidth
            disabled={providersIsLoading || !!planId}
            value={formik.values.provider}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.provider && Boolean(formik.errors.provider)}
            helperText={formik.touched.provider && formik.errors.provider}
          >
            {providersIsLoading ? (
              <MenuItem key={0} value={0} disabled>
                Loading...
              </MenuItem>
            ) : (
              providers?.map((provider) => (
                <MenuItem key={provider?.id} value={provider?.id}>
                  {provider?.name}
                </MenuItem>
              ))
            )}
          </TextField>
        </Stack>
      </Stack>
      <Stack gap={1}>
        <Stack name="tickets" id="tickets">
          {formik.values.tickets?.map((ticket, index) => (
            <PlanTicketType key={ticket?.id} index={index} formik={formik} />
          ))}
        </Stack>
        <Button
          variant="outlined"
          size="small"
          sx={{ width: "fit-content", mt: 2 }}
          onClick={addTicketType}
          startIcon={<IoAddOutline />}
        >
          Add Ticket Type
        </Button>
      </Stack>

      <Stack flexDirection="row" mt={3} gap={2} alignSelf="flex-end">
        <Button
          disabled={isLoading}
          variant="contained"
          color="grey"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <LoadingButton type="submit" loading={isLoading} variant="contained">
          {!!planId ? "Edit Plan" : "Create Plan"}
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
