import { Divider, Stack, TextField, Typography } from "@mui/material";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import Dialog from "./Dialog/Dialog";
import AddEventPlan from "./Dialog/DialogContent/AddEventPlan";
import AddVerifierDialog from "./Dialog/DialogContent/AddVerifierDialog";
import DeleteDialog from "./Dialog/DialogContent/DeleteDialog";

export default function ProviderRow({ provider, eventPlans, verifiers }) {
  return (
    <Stack>
      <Typography variant="h5" fontWeight={500}>
        {provider}
      </Typography>
      <Stack
        flexDirection={{ md: "row" }}
        justifyContent="space-between"
        flexWrap="wrap"
        gap={5}
      >
        <Stack>
          <Typography variant="h6" fontWeight={400} mt={2}>
            Event Plans
          </Typography>
          <Stack gap={{ xs: 4, md: 1 }}>
            {!!eventPlans?.length ? (
              eventPlans?.map((plan) => (
                <Stack
                  flexDirection={{ md: "row" }}
                  gap={2}
                  key={plan?.id}
                  mt={1.5}
                >
                  <TextField size="small" disabled value={plan?.name} />
                  <Dialog
                    dialogMaxWidth={750}
                    dialogContent={<AddEventPlan planId={plan?.id} />}
                    btnProps={{
                      variant: "contained",
                      sx: { py: 1, px: 2 },
                      startIcon: <AiOutlineEdit color="white" />,
                    }}
                  >
                    Edit
                  </Dialog>
                  <Dialog
                    dialogMaxWidth={450}
                    dialogContent={
                      <DeleteDialog isPlan id={plan?.id} name={plan?.name} />
                    }
                    btnProps={{
                      variant: "contained",
                      color: "error",
                      sx: { py: 1, px: 2 },
                      disabled: true,
                      startIcon: <RiDeleteBin5Line color="white" />,
                    }}
                  >
                    Delete
                  </Dialog>
                </Stack>
              ))
            ) : (
              <Typography variant="body2" mt={2}>
                No Plans
              </Typography>
            )}
          </Stack>
        </Stack>

        <Stack>
          <Typography variant="h6" fontWeight={400} mt={2}>
            Verifiers
          </Typography>
          <Stack gap={{ xs: 4, md: 1 }}>
            {!!verifiers?.length ? (
              verifiers?.map((verifier) => (
                <Stack
                  flexDirection={{ md: "row" }}
                  gap={2}
                  key={verifier?.id}
                  mt={1.5}
                >
                  <TextField size="small" disabled value={verifier?.username} />
                  <Dialog
                    dialogMaxWidth={750}
                    dialogContent={<AddVerifierDialog userId={verifier?.id} />}
                    btnProps={{
                      variant: "contained",
                      sx: { py: 1, px: 2 },
                      startIcon: <AiOutlineEdit color="white" />,
                    }}
                  >
                    Edit
                  </Dialog>
                  <Dialog
                    dialogMaxWidth={450}
                    dialogContent={
                      <DeleteDialog
                        id={verifier?.id}
                        name={verifier?.username}
                      />
                    }
                    btnProps={{
                      variant: "contained",
                      color: "error",
                      sx: { py: 1, px: 2 },
                      disabled: true,
                      startIcon: <RiDeleteBin5Line color="white" />,
                    }}
                  >
                    Delete
                  </Dialog>
                </Stack>
              ))
            ) : (
              <Typography variant="body2" mt={2}>
                No Verifiers
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Divider
        sx={{
          borderColor: (theme) => theme.palette.primary.main,
          boxShadow: "0px 5px 4px rgba(0, 0, 0, 0.25);",
          my: 5,
        }}
      />
    </Stack>
  );
}
