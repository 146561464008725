import { createContext, useState } from "react";
import { attachToken } from "../config/axios";

const TOKEN = "TOKEN";

const AuthContext = createContext({
  token: null,
  saveToken: (token) => {},
  logout: () => {},
});

const retriveData = (key) => {
  const data = localStorage.getItem(key);
  return data ?? null;
};

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(retriveData(TOKEN));

  const saveToken = (token) => {
    attachToken(token);
    setToken(token);
    localStorage.setItem(TOKEN, token);
  };

  const logout = () => {
    setToken(null);
    localStorage.removeItem(TOKEN);
  };

  const value = {
    token,
    saveToken,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
