import { LoadingButton } from "@mui/lab";
import { Button, Stack, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { hideEventEndpoint } from "../../../config/api";

export default function HideEventDialog({ handleClose, id }) {
  const queryClinet = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationKey: ["hide-event", id],
    mutationFn: hideEventEndpoint,
    onSuccess: () => {
      handleClose();
      queryClinet.invalidateQueries({
        queryKey: ["events"],
      });
    },
  });

  const handleClick = async () => {
    mutate(id);
  };

  return (
    <Stack gap={3}>
      <Typography variant="h5" color="primary.main">
        Hide Event
      </Typography>
      <Typography lineHeight={2}>
        By clicking hide, the event will be removed from GO application. Do you
        want to continue?
      </Typography>
      <Stack flexDirection="row" gap={3} alignSelf="flex-end">
        <Button
          variant="contained"
          onClick={handleClose}
          color="grey"
          sx={{ color: "white" }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          color="error"
          onClick={handleClick}
        >
          Hide
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
