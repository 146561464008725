import { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "../config/axios";
import useAuth from "./useAuth";

export default function useAxiosInterceptors() {
  const { token, logout } = useAuth();
  useEffect(() => {
    const requestIntercept = axios.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"] && token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          logout();
        }
        toast.error(
          error?.response?.data?.message ||
            error?.message ||
            "An error occurred",
          {
            toastId: "axios",
          }
        );

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestIntercept);
      axios.interceptors.response.eject(responseIntercept);
    };
  }, [token, logout]);
}
