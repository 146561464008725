import {
  Button,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  InputAdornment,
  CircularProgress,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ImageInput from "../ImageInput";
import TicketDetails from "../TicketDetails";
import { errorMsg } from "../../../config/util";
import { IoAddOutline } from "react-icons/io5";
import Dialog from "../Dialog";
import PublishDialogConent from "./PublishDialogConent";
import {
  duplicateEvent,
  editEvent,
  getEvent,
  getEventsPlans,
  getEventType,
  publishEvent,
} from "../../../config/api";
import useProvidersList from "../../../hooks/useProvidersList";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import DownloadGuestsList from "../../DownloadGuestsList";
import { enGB } from "date-fns/locale";
import { toast } from "react-toastify";

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);

const validationSchema = yup.object({
  coverImg: yup
    .object()
    .required(errorMsg.required)
    .shape({
      src: yup.string().required(errorMsg.required),
      // raw: yup.string().required(),
      dim: yup
        .array()
        // .required(errorMsg.required)
        .test(
          "is-valid-dim",
          errorMsg.dimension,
          (value, context) =>
            !context?.parent?.src?.includes("base64") ||
            (value?.length && value[0] === 757 && value[1] === 720)
        ),
    }),
  bannerImg: yup
    .object()
    .required(errorMsg.required)
    .shape({
      src: yup.string().required(errorMsg.required),
      // raw: yup.string().required(),
      dim: yup
        .array()
        // .required(errorMsg.required)
        .test("is-valid-dim", errorMsg.dimension, (value, context) => {
          return (
            !context?.parent?.src?.includes("base64") ||
            (value?.length && value[0] === 2400 && value[1] === 1260)
          );
        }),
    }),
  provider: yup.string().required(errorMsg.required),
  eventPlan: yup.string().required(errorMsg.required),
  name: yup.string().required(errorMsg.required),
  priority: yup.number().required(errorMsg.required),
  nameArabic: yup.string().required(errorMsg.required),
  venue: yup.string().required(errorMsg.required),
  venueArabic: yup.string().required(errorMsg.required),
  eventAbout: yup.string().required(errorMsg.required),
  eventAboutAr: yup.string().required(errorMsg.required),
  goMargin: yup
    .number()
    .lessThan(100, "provide a valid margin between (0 - 100)%")
    .required(errorMsg.required),
  startDate: yup
    .date()
    .nullable()
    // .min(yesterday, errorMsg.invalid) //todo uncomment
    .typeError(errorMsg.required)
    .required(errorMsg.required),
  endDate: yup
    .date()
    .nullable()
    .min(yup.ref("startDate"), errorMsg.endAfterStart)
    .typeError(errorMsg.required)
    .required(errorMsg.required),
  ticketCloseTime: yup.string().required(errorMsg.required),
  eventType: yup.string().required(errorMsg.required),
  ticket: yup.array().of(
    yup.object().shape({
      ticketType: yup.string().required(errorMsg.required),
      // .oneOf(ticketType.map((type) => type)),
      quantity: yup
        .number()
        .required(errorMsg.required)
        .min(1, errorMsg.invalid),
      unitPrice: yup
        .number()
        .required(errorMsg.required)
        .min(1, errorMsg.invalid),
    })
  ),
});

const getImageId = (image, editMode) => {
  return editMode || !!!image
    ? ""
    : image.includes("base64")
    ? image
    : +image?.split("/")?.slice(-1)[0];
};

export default function EventDetailsDialog({
  id,
  editMode,
  viewMode,
  handleClose,
  duplicateMode,
}) {
  const { providers, providersIsLoading } = useProvidersList();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: editMode
      ? editEvent
      : duplicateMode
      ? duplicateEvent
      : publishEvent,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["events"],
      });
      handleClose();
      toast.success(
        editMode
          ? "Event has been edited successfully"
          : "Event has been published successfully"
      );
    },
  });

  const onSubmit = async ({
    eventPlan,
    eventType,
    startDate,
    endDate,
    ticketCloseTime,
    priority,
    name,
    nameArabic,
    venue,
    venueArabic,
    goMargin,
    coverImg,
    bannerImg,
    ticket,
    eventAbout,
    eventAboutAr,
  }) => {
    let data = {
      eventPlanId: eventPlan,
      eventTypeId: eventType,
      beginTime: new Date(startDate).toISOString(),
      endTime: new Date(endDate).toISOString(),
      closeTicketsAt: new Date(ticketCloseTime).toISOString(),
      priority: priority,
      about: {
        en: eventAbout,
        ar: eventAboutAr,
      },
      title: {
        en: name,
        ar: nameArabic,
      },
      venue: {
        en: venue,
        ar: venueArabic,
      },
      margin: goMargin,
      cover: getImageId(bannerImg?.src, editMode),
      poster: getImageId(coverImg?.src, editMode),
      eventTickets: ticket?.map(
        ({ ticketType, quantity, unitPrice, backendId }) => {
          let ticketData = {
            ticketOptionId: ticketType,
            capacity: quantity,
            price: unitPrice,
          };
          if (!!backendId) {
            ticketData.id = backendId;
          }
          return ticketData;
        }
      ),
    };

    if (!!id) {
      data = {
        id: id,
        body: data,
      };
    }
    mutate(data);
  };

  const formik = useFormik({
    initialValues: {
      coverImg: { src: "" },
      bannerImg: { src: "" },
      provider: "",
      eventPlan: "",
      priority: "",
      name: "",
      nameArabic: "",
      venue: "",
      venueArabic: "",
      eventAbout: "",
      eventAboutAr: "",
      goMargin: "",
      startDate: today,
      endDate: today,
      ticketCloseTime: today,
      eventType: "",
      ticket: [
        {
          ticketType: "",
          quantity: "",
          unitPrice: "",
          id: Math.random(),
        },
      ],
    },
    validationSchema,
    onSubmit,
  });

  const {
    isLoading: eventDataIsLoading,
    isError,
    error,
  } = useQuery({
    enabled: !!id,
    cacheTime: 0,
    staleTime: Infinity,
    queryKey: ["event", id],
    queryFn: getEvent.bind(this, id),
    onSuccess: (eventData) => {
      const values = {
        coverImg: { src: eventData?.data?.poster },
        bannerImg: { src: eventData?.data?.cover },
        provider: eventData?.data?.providerId,
        eventPlan: eventData?.data?.eventPlanId,
        priority: eventData?.data?.priority,
        name: eventData?.data?.eventName?.en,
        nameArabic: eventData?.data?.eventName?.ar,
        venue: eventData?.data?.venue?.en,
        venueArabic: eventData?.data?.venue?.ar,
        eventAbout: eventData?.data?.about?.en,
        eventAboutAr: eventData?.data?.about?.ar,
        goMargin: eventData?.data?.margin,
        startDate: !!duplicateMode ? today : eventData?.data?.startDate,
        endDate: !!duplicateMode ? today : eventData?.data?.endDate,
        ticketCloseTime: !!duplicateMode ? today : eventData?.data?.closedAt,
        eventType: eventData?.data?.eventTypeId,
        ticket: eventData?.data?.ticketTypes?.map((ticket) => {
          let ticketData = {
            id: ticket?.id,
            unitPrice: ticket?.price,
            quantity: ticket?.totalTickets,
            ticketType: ticket?.ticketTypeId,
            name: ticket?.name,
          };
          if (!!!duplicateMode) {
            ticketData.backendId = ticket?.id;
          }
          return ticketData;
        }),
      };
      formik.setValues(values);
    },
  });

  const { data: eventTypeList, isLoading: eventTypeIsLoading } = useQuery({
    staleTime: Infinity,
    queryKey: ["type"],
    queryFn: getEventType,
  });

  const { data: eventPlans, isLoading: plansAreLoading } = useQuery({
    staleTime: Infinity,
    queryKey: ["plans"],
    queryFn: getEventsPlans,
  });

  if (!!id && eventDataIsLoading)
    return <CircularProgress sx={{ my: 5, display: "block", mx: "auto" }} />;
  if (!!id && isError)
    return (
      <>
        <Typography mt={5} variant="h5">
          Error fetching data
        </Typography>
        <Typography mt={1} mb={5}>
          {error?.response?.data?.message}
        </Typography>
      </>
    );

  const publish = () => {
    formik.handleSubmit();
  };

  const addTicketHandle = () => {
    const newTickets = formik.values.ticket.concat({
      id: Math.random(),
      ticketType: "",
      quantity: "",
      unitPrice: "",
    });

    formik.setFieldValue("ticket", newTickets);
  };

  const planOptions = !!formik.values.provider
    ? eventPlans?.data?.filter(
        (plan) => plan?.providerId === formik.values.provider
      )
    : [];

  const handleProviderChange = (e) => {
    formik.handleChange(e);
    formik.setFieldValue("ticket", [
      { ticketType: "", quantity: "", unitPrice: "", id: Math.random() },
    ]);
    formik.setFieldValue("eventPlan", "");
  };

  const handlePlanChange = (e) => {
    formik.handleChange(e);

    const planTickets = planOptions
      ?.filter((plan) => {
        return plan?.id === e.target.value;
      })[0]
      ?.ticketOptions?.map((ticket) => {
        return {
          ticketType: ticket?.id,
          quantity: "1000",
          unitPrice: ticket?.price,
          id: ticket?.id,
        };
      });

    formik.setFieldValue(
      "ticket",
      !!planTickets.length
        ? planTickets
        : [{ ticketType: "", quantity: "", unitPrice: "", id: Math.random() }]
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <Stack
        minWidth="min-content"
        component="form"
        onSubmit={formik.handleSubmit}
      >
        <Stack
          flexDirection="row"
          justifyItems="space-between"
          width="100%"
          gap={{ xs: 2, sm: 5 }}
        >
          <ImageInput
            image={formik.values.coverImg}
            setField={formik.setFieldValue}
            field="coverImg"
            error={formik.touched.coverImg && Boolean(formik.errors.coverImg)}
            errorMsg={formik.touched.coverImg && formik.errors.coverImg}
            setTouched={formik.setFieldTouched}
            placeholder="757 x 720"
            groupProps={{ width: "40%" }}
            viewMode={!!viewMode}
          >
            Upload Poster
          </ImageInput>
          <ImageInput
            image={formik.values.bannerImg}
            setField={formik.setFieldValue}
            field="bannerImg"
            error={formik.touched.bannerImg && Boolean(formik.errors.bannerImg)}
            errorMsg={formik.touched.bannerImg && formik.errors.bannerImg}
            setTouched={formik.setFieldTouched}
            placeholder="1268 x 2400"
            groupProps={{ width: "60%" }}
            viewMode={!!viewMode}
          >
            Upload Header
          </ImageInput>
        </Stack>
        <Stack gap={{ xs: 1, sm: 3 }}>
          <Stack
            flexDirection={{ xs: "column", sm: "row" }}
            gap={{ xs: 1, sm: 2 }}
          >
            <Stack gap={0.5} flexGrow={1}>
              <InputLabel>Provider</InputLabel>
              <TextField
                multiline
                id="provider"
                name="provider"
                variant="outlined"
                size="small"
                disabled={providersIsLoading || !!viewMode || !!editMode}
                select
                fullWidth
                value={formik.values.provider}
                onChange={handleProviderChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.provider && Boolean(formik.errors.provider)
                }
                helperText={formik.touched.provider && formik.errors.provider}
              >
                {!providersIsLoading ? (
                  providers?.map((provider) => (
                    <MenuItem key={provider?.id} value={provider?.id}>
                      {provider?.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key={0} value={0} disabled>
                    Loading...
                  </MenuItem>
                )}
              </TextField>
            </Stack>
            <Stack gap={0.5} flexGrow={1}>
              <InputLabel>Event Plan</InputLabel>
              <TextField
                multiline
                id="eventPlan"
                name="eventPlan"
                variant="outlined"
                disabled={
                  !!viewMode ||
                  !!editMode ||
                  !!!formik.values.provider ||
                  plansAreLoading
                }
                size="small"
                select
                fullWidth
                value={formik.values.eventPlan}
                onChange={handlePlanChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.eventPlan && Boolean(formik.errors.eventPlan)
                }
                helperText={formik.touched.eventPlan && formik.errors.eventPlan}
              >
                {!plansAreLoading || !!formik.values.provider ? (
                  !!planOptions?.length ? (
                    planOptions?.map((plan) => (
                      <MenuItem key={plan?.id} value={plan?.id}>
                        {plan?.planName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem key={0} value={0} disabled>
                      No Plans
                    </MenuItem>
                  )
                ) : (
                  <MenuItem key={0} value={0} disabled>
                    Loading...
                  </MenuItem>
                )}
              </TextField>
            </Stack>
            <Stack gap={0.5} flexGrow={1}>
              <InputLabel>Event Name</InputLabel>
              <TextField
                multiline
                disabled={!!viewMode}
                id="name"
                name="name"
                variant="outlined"
                size="small"
                fullWidth
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Stack>
            <Stack gap={0.5} flexGrow={1}>
              <InputLabel>Event Arabic Name</InputLabel>
              <TextField
                multiline
                disabled={!!viewMode}
                dir="rtl"
                id="nameArabic"
                name="nameArabic"
                variant="outlined"
                size="small"
                fullWidth
                value={formik.values.nameArabic}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.nameArabic && Boolean(formik.errors.nameArabic)
                }
                helperText={
                  formik.touched.nameArabic && formik.errors.nameArabic
                }
              />
            </Stack>
            <Stack gap={0.5} flexGrow={1}>
              <InputLabel>Priority</InputLabel>
              <TextField
                multiline
                disabled={!!viewMode}
                id="priority"
                name="priority"
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                value={formik.values.priority}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.priority && Boolean(formik.errors.priority)
                }
                helperText={formik.touched.priority && formik.errors.priority}
              />
            </Stack>
          </Stack>
          <Stack
            flexDirection={{ xs: "column", sm: "row" }}
            gap={{ xs: 1, sm: 2 }}
          >
            <Stack gap={0.5} flexGrow={1}>
              <InputLabel>Event Description</InputLabel>
              <TextField
                multiline
                disabled={!!viewMode}
                id="eventAbout"
                name="eventAbout"
                variant="outlined"
                size="small"
                fullWidth
                value={formik.values.eventAbout}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.eventAbout && Boolean(formik.errors.eventAbout)
                }
                helperText={
                  formik.touched.eventAbout && formik.errors.eventAbout
                }
              />
            </Stack>
            <Stack gap={0.5} flexGrow={1}>
              <InputLabel>Event Arabic Description</InputLabel>
              <TextField
                multiline
                disabled={!!viewMode}
                dir="rtl"
                id="eventAboutAr"
                name="eventAboutAr"
                variant="outlined"
                size="small"
                fullWidth
                value={formik.values.eventAboutAr}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.eventAboutAr &&
                  Boolean(formik.errors.eventAboutAr)
                }
                helperText={
                  formik.touched.eventAboutAr && formik.errors.eventAboutAr
                }
              />
            </Stack>
          </Stack>

          <Stack
            flexDirection={{ xs: "column", sm: "row" }}
            gap={{ xs: 1, sm: 2 }}
          >
            <Stack gap={0.5} flexGrow={1}>
              <InputLabel>Event Type</InputLabel>
              <TextField
                multiline
                name="eventType"
                variant="outlined"
                size="small"
                disabled={!!viewMode || eventTypeIsLoading}
                select
                value={formik.values.eventType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.eventType && Boolean(formik.errors.eventType)
                }
                helperText={formik.touched.eventType && formik.errors.eventType}
              >
                {!eventTypeIsLoading ? (
                  eventTypeList?.data?.map((type) => (
                    <MenuItem key={type?.id} value={type?.id}>
                      {type?.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key={0} value={0} disabled>
                    Loading...
                  </MenuItem>
                )}
              </TextField>
            </Stack>
            <Stack gap={0.5} flexGrow={1}>
              <InputLabel>Venue</InputLabel>
              <TextField
                multiline
                disabled={!!viewMode}
                id="venue"
                name="venue"
                variant="outlined"
                size="small"
                fullWidth
                value={formik.values.venue}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.venue && Boolean(formik.errors.venue)}
                helperText={formik.touched.venue && formik.errors.venue}
              />
            </Stack>
            <Stack gap={0.5} flexGrow={1}>
              <InputLabel>Venue Arabic</InputLabel>
              <TextField
                multiline
                disabled={!!viewMode}
                dir="rtl"
                id="venueArabic"
                name="venueArabic"
                variant="outlined"
                size="small"
                fullWidth
                value={formik.values.venueArabic}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.venueArabic &&
                  Boolean(formik.errors.venueArabic)
                }
                helperText={
                  formik.touched.venueArabic && formik.errors.venueArabic
                }
              />
            </Stack>
            <Stack gap={0.5} flexGrow={1}>
              <InputLabel>GO Margin</InputLabel>
              <TextField
                multiline
                disabled={!!viewMode}
                id="goMargin"
                name="goMargin"
                variant="outlined"
                type="number"
                size="small"
                fullWidth
                value={formik.values.goMargin}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.goMargin && Boolean(formik.errors.goMargin)
                }
                helperText={formik.touched.goMargin && formik.errors.goMargin}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Stack>

          <Stack
            flexDirection={{ xs: "column", sm: "row" }}
            gap={{ xs: 1, sm: 2 }}
          >
            <Stack gap={0.5} flexGrow={1}>
              <InputLabel>Start Date</InputLabel>
              <DateTimePicker
                // inputFormat="dd/MM/yyyy hh:mm bb"
                ampm={true}
                value={formik.values.startDate}
                onChange={(newValue) =>
                  formik.setFieldValue("startDate", newValue)
                }
                disabled={!!viewMode}
                renderInput={(params) => (
                  <TextField
                    multiline
                    {...params}
                    id="startDate"
                    name="startDate"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.startDate &&
                      Boolean(formik.errors.startDate)
                    }
                    helperText={
                      formik.touched.startDate && formik.errors.startDate
                    }
                  />
                )}
              />
            </Stack>
            <Stack gap={0.5} flexGrow={1}>
              <InputLabel>End Date</InputLabel>
              <DateTimePicker
                ampm={true}
                value={formik.values.endDate}
                onChange={(newValue) =>
                  formik.setFieldValue("endDate", newValue)
                }
                disabled={!!viewMode}
                renderInput={(params) => (
                  <TextField
                    multiline
                    {...params}
                    id="endDate"
                    name="endDate"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.endDate && Boolean(formik.errors.endDate)
                    }
                    helperText={formik.touched.endDate && formik.errors.endDate}
                  />
                )}
              />
            </Stack>
            <Stack gap={0.5} flexGrow={1}>
              <InputLabel>Tickets Closed at</InputLabel>
              <DateTimePicker
                ampm={true}
                value={formik.values.ticketCloseTime}
                onChange={(newValue) =>
                  formik.setFieldValue("ticketCloseTime", newValue)
                }
                disabled={!!viewMode}
                renderInput={(params) => (
                  <TextField
                    multiline
                    {...params}
                    id="ticketCloseTime"
                    name="ticketCloseTime"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.ticketCloseTime &&
                      Boolean(formik.errors.ticketCloseTime)
                    }
                    helperText={
                      formik.touched.ticketCloseTime &&
                      formik.errors.ticketCloseTime
                    }
                  />
                )}
              />
            </Stack>
          </Stack>

          {!!!viewMode && (
            <>
              <Stack gap={1}>
                <Stack name="ticket" id="ticket" gap={1}>
                  {formik.values.ticket?.map((ticket, index) => {
                    return (
                      <TicketDetails
                        key={ticket.id}
                        index={index}
                        formik={formik}
                        planOptions={planOptions}
                        editMode={!!ticket?.backendId}
                      />
                    );
                  })}
                </Stack>

                <Button
                  variant="outlined"
                  size="small"
                  sx={{ width: "fit-content", mt: 1 }}
                  onClick={addTicketHandle}
                  startIcon={<IoAddOutline />}
                >
                  Add Ticket Type
                </Button>
              </Stack>
            </>
          )}
          {!!viewMode && (
            <div>
              <InputLabel>Ticket Type</InputLabel>

              <Stack
                borderRadius={2}
                boxShadow="inset -2px 0px 3px rgba(0, 0, 0, 0.25)"
                gap={1}
                p={3}
                mt={1}
              >
                {formik.values.ticket?.map((ticket) => {
                  return (
                    <Stack
                      key={ticket?.id}
                      flexDirection="row"
                      flexWrap="wrap"
                      justifyContent="space-between"
                      gap={0.5}
                    >
                      <Typography component="li">{ticket?.name}</Typography>
                      <Typography>{`Unit Price: ${ticket?.unitPrice.toLocaleString(
                        "en-US"
                      )} SDG`}</Typography>
                    </Stack>
                  );
                })}
              </Stack>
            </div>
          )}

          <Stack
            flexDirection="row"
            alignSelf="flex-end"
            gap={{ xs: 1, sm: 2 }}
          >
            {viewMode && (
              <DownloadGuestsList id={id} name={formik?.values?.name} />
            )}

            <Button
              variant="contained"
              color="secondary"
              disabled={isLoading}
              sx={{ width: "fit-content", color: "white" }}
              onClick={handleClose}
            >
              {viewMode ? "Close" : "Discard"}
            </Button>

            {!viewMode && (
              <Dialog
                dialogContent={<PublishDialogConent publish={publish} />}
                dialogMaxWidth={450}
                btnProps={{
                  variant: "contained",
                  sx: { width: "fit-content" },
                  loading: isLoading,
                }}
              >
                {editMode ? "Edit" : "Save And Publish"}
              </Dialog>
            )}
          </Stack>
        </Stack>
      </Stack>
    </LocalizationProvider>
  );
}
