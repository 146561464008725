import { CircularProgress, Stack, Typography } from "@mui/material";
import Dialog from "../components/Dialog/Dialog";
import EventDetailsDialog from "../components/Dialog/DialogContent/EventDetailsDialog";
import EventCell from "../components/EventCell";
import { getEvents } from "../config/api";

import { IoAddOutline } from "react-icons/io5";
import { useState } from "react";
import Searchbar from "../components/Searchbar";
import { useQuery } from "@tanstack/react-query";

export default function Events() {
  const [searchTerm, setSearchTerm] = useState("");
  const {
    data: events,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["events", searchTerm],
    queryFn: getEvents.bind(this, searchTerm),
    staleTime: Infinity,
    refetchInterval: 1000 * 60 * 3,
    networkMode: "always",
  });

  if (isError) {
    return <Typography> Somethig went wrong!</Typography>;
  }

  const search = async (searchTerm) => {
    setSearchTerm(searchTerm);
  };

  return (
    <Stack>
      <Stack
        flexDirection={{ md: "row" }}
        justifyContent="space-between"
        width="100%"
        gap={2}
        mb={3}
      >
        <Dialog
          dialogContent={<EventDetailsDialog />}
          btnProps={{
            variant: "contained",
            sx: { px: 8, py: 1 },
            startIcon: <IoAddOutline color="white" />,
          }}
        >
          Add New Event
        </Dialog>
        <Searchbar search={search} searchTerm={searchTerm} />
      </Stack>

      {isLoading ? (
        <CircularProgress sx={{ display: "block", mx: "auto" }} />
      ) : events?.data?.length === 0 ? (
        <Typography>Sorry there is no events to show</Typography>
      ) : (
        <Stack>
          {events?.data?.map((event) => (
            <EventCell
              key={event.id}
              url={event?.poster}
              name={event?.name}
              date={event?.date}
              time={event?.time}
              place={event?.venue}
              id={event.id}
              host={event?.host}
              isHidden={!event?.isShowing}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
}
