import Events from "../pages/Events";
import Login from "../pages/Login";
import PageNotFound from "../pages/PageNotFound";
import Providers from "../pages/Providers";

export const id = {
  events: "events",
  login: "login",
  providers: "providers",
  notFound: "not-found",
};

export const path = {
  events: "/",
  login: "/login",
  providers: "/providers",
  notFound: "*",
};

export const routes = [
  {
    id: id.events,
    path: path.events,
    element: <Events />,
  },
  {
    id: id.providers,
    path: path.providers,
    element: <Providers />,
  },
];

export const fullPage = [
  {
    id: id.login,
    path: path.login,
    element: <Login />,
  },
  {
    id: id.notFound,
    path: path.notFound,
    element: <PageNotFound />,
  },
];
