import axios from "axios";

const BASE_URL = "https://events.goteclabs.com:3333/admin/";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    lancode: "en",
  },
});

export const attachToken = (token) => {
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export default axiosInstance;
