import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ThemeProvider } from "./config/theme";
import { AuthProvider } from "./store/auth-cxt";
import ToastContainer from "./components/ToastContainer";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "react-toastify/dist/ReactToastify.min.css";
import "./assets/css/index.css";
import "./assets/css/fonts.css";
import "./assets/fonts/futur.ttf";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ProvidersProvider } from "./store/providers-cxt";

const queryClinet = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClinet}>
        <ThemeProvider>
          <AuthProvider>
            <ProvidersProvider>
              <ToastContainer />
              <App />
              {/* <ReactQueryDevtools /> */}
            </ProvidersProvider>
          </AuthProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
