import { Box, Button, Stack, Typography } from "@mui/material";
import { useRef } from "react";

export default function ImageInput({
  image,
  setField,
  field,
  error,
  errorMsg,
  setTouched,
  placeholder,
  groupProps,
  viewMode,
  disabled,
  children,
}) {
  const ref = useRef();

  const handleChange = (e) => {
    setTouched([field][0], true);

    const selectedFile = e.target.files[0];
    const reader = new FileReader();
    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    reader.onload = (readerEvent) => {
      const imageBase64 = readerEvent.target.result;

      const image = new Image();
      image.src = imageBase64;
      image.onload = () => {
        const dim = [image.width, image.height];
        setField([field], {
          src: imageBase64,
          dim,
        });
      };
    };
  };
  return (
    <Stack
      name={[field][0]}
      id={[field][0]}
      spacing={1}
      alignItems="flex-end"
      {...groupProps}
      flexGrow={1}
    >
      {/* <Stack flexDirection="row" width="100%" alignItems="flex-end" gap={1}> */}
      {!!image?.src ? (
        <Box
          component="img"
          src={image.src}
          height={100}
          width="100%"
          borderRadius={2}
          sx={{
            objectFit: "contain",
            ":hover": {
              cursor: "pointer",
            },
          }}
          onClick={viewMode ? () => {} : () => ref.current?.click()}
        />
      ) : (
        <Stack
          width="100%"
          minHeight={100}
          justifyContent="center"
          border={1}
          borderRadius={2}
          borderColor={(theme) => (error ? "red" : theme.palette.grey.main)}
          onClick={viewMode ? () => {} : () => ref.current?.click()}
          sx={{
            ":hover": {
              cursor: "pointer",
            },
          }}
        >
          <Typography
            variant="h4"
            fontWeight={50}
            textAlign="center"
            color={(theme) => (error ? theme.palette.error.main : "black")}
          >
            {placeholder}
          </Typography>
        </Stack>
      )}
      {!viewMode && (
        <Button
          size="small"
          variant="outlined"
          sx={{
            width: "fit-content",
          }}
          disabled={viewMode}
          onClick={() => ref.current?.click()}
        >
          {children}
        </Button>
      )}
      {/* </Stack> */}

      <input
        ref={ref}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleChange}
        disabled={viewMode}
      />

      <Typography
        color={(theme) => theme.palette.error.main}
        varient="caption"
        alignSelf="flex-start"
        fontSize="0.8rem"
        pb={1}
        pl={2}
      >
        {errorMsg && Object.values(errorMsg)[0]}
      </Typography>
    </Stack>
  );
}
