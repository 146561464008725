import { IconButton, InputAdornment, TextField } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";

export default function Searchbar({ search, searchTerm }) {
  const handleChange = (e) => {
    search(e.target.value);
  };

  const handleSearch = () => {
    searchTerm && search(searchTerm);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const clear = () => {
    search("");
  };

  return (
    <TextField
      size="small"
      placeholder="Search"
      value={searchTerm}
      color="grey"
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      sx={{
        "& .MuiOutlinedInput-root": {
          backgroundColor: "#D4D4D4",
          color: "white",
          fontWeight: "bold",
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {searchTerm && (
              <IconButton size="small" edge="end" onClick={clear}>
                <AiOutlineClose color="white" />
              </IconButton>
            )}
            <IconButton size="small" edge="end" onClick={handleSearch}>
              <BsSearch color="white" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
