import axiosInstance from "../config/axios";

export const login = (body) => {
  return axiosInstance.post("auth", body);
};

export const getEvents = (searchTerm) => {
  return axiosInstance.get(
    `events${!!searchTerm ? `?text=${searchTerm}` : ""}`
  );
};

export const getProvidersList = () => {
  return axiosInstance.get("providers");
};

export const getEventsPlans = () => {
  return axiosInstance.get("plans");
};

export const getEventType = () => {
  return axiosInstance.get("types");
};

export const searchEvent = async (searchTerm) => {
  try {
    //todo
    const response = await axiosInstance.get(
      `http://www.tasalee.net:5000/search?text=${searchTerm}`
    );
    return response?.data;
  } catch (error) {}
  return null;
};

export const publishEvent = (body) => {
  return axiosInstance.post("events", body);
};

export const editEvent = (data) => {
  return axiosInstance.put(`events/${data.id}`, data.body);
};

export const hideEventEndpoint = (id) => {
  // return axiosInstance.put(`events/${id}`); //todo
};

export const getEvent = (id) => {
  return axiosInstance.get(`events/${id}`);
};

export const duplicateEvent = (data) => {
  return axiosInstance.post(`events/${data.id}/duplicate`, data.body);
};

export const getProvidersWithDetails = () => {
  return axiosInstance.get("providers/details");
};

export const postVerifier = (body) => {
  return axiosInstance.post("verifiers", body);
};

export const getVerifier = (id) => {
  return axiosInstance.get(`verifiers/${id}`);
};

export const updateVerifier = (data) => {
  return axiosInstance.put(`verifiers/${data?.id}`, data?.body);
};

export const deleteVerifier = (id) => {
  return axiosInstance.delete(`verifiers/${id}`); //todo
};

export const postPlan = (body) => {
  return axiosInstance.post("plans", body);
};

export const getPlan = (id) => {
  return axiosInstance.get(`plans/${id}`);
};

export const updatePlan = (data) => {
  return axiosInstance.put(`plans/${data?.id}`, data?.body);
};

export const deletePlan = (id) => {
  return axiosInstance.delete(`plans/${id}`); //todo
};

export const getGuestList = (id) => {
  return axiosInstance.get(`events/${id}/guests`, { responseType: "blob" });
};
