import { Suspense, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Loader from "./components/Loader";
import { attachToken } from "./config/axios";
import { fullPage, path, routes } from "./config/routes";
import useAuth from "./hooks/useAuth";
import useAxiosInterceptors from "./hooks/useAxiosInterceptors";
import useScrollToTop from "./hooks/useScrollToTop";

function App() {
  useAxiosInterceptors();
  useScrollToTop();

  const { token } = useAuth();
  !!token && attachToken(token);

  const appRoutes = useMemo(
    () => (
      <Route element={<Layout />}>
        {routes.map((route) => (
          <Route
            key={route.id}
            path={route.path}
            loader={route?.loader}
            element={!!token ? route.element : <Navigate to={path.login} />}
          />
        ))}
      </Route>
    ),
    [token]
  );

  const appFullPage = useMemo(
    () =>
      fullPage.map((route) => (
        <Route key={route.id} path={route.path} element={route.element} />
      )),
    []
  );

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {appFullPage}
        {appRoutes}
      </Routes>
    </Suspense>
  );
}

export default App;
