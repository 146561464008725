import { Box, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import Drawer, { drawerWidth } from "./Drawer";

export default function Layout() {
  return (
    <Stack direction={{ xs: "column", sm: "row" }}>
      <Drawer />
      <Box
        component="main"
        flexGrow={1}
        maxWidth={{ sm: `calc(100% - ${drawerWidth}px)` }}
        px={{ xs: 2, sm: 5 }}
        py={{ xs: 3, sm: 8 }}
      >
        <Outlet />
      </Box>
    </Stack>
  );
}
