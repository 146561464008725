import { Button, Stack, Typography } from "@mui/material";
import React from "react";

export default function PublishDialogConent({ handleClose, publish }) {
  const handleProceed = () => {
    handleClose();
    publish();
  };
  return (
    <Stack gap={3}>
      <Typography variant="h5" color="primary.main">
        Event Publish Confirmation
      </Typography>
      <Typography lineHeight={2}>
        By clicking Proceed the event will be published to GO application. Do
        you want to continue?
      </Typography>
      <Stack flexDirection="row" gap={3} alignSelf="flex-end">
        <Button
          variant="contained"
          onClick={handleClose}
          color="grey"
          sx={{ color: "white" }}
        >
          Cancel
        </Button>
        <Button type="submit" variant="contained" onClick={handleProceed}>
          Proceed
        </Button>
      </Stack>
    </Stack>
  );
}
