import { LoadingButton } from "@mui/lab";
import { Button, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { deletePlan, deleteVerifier } from "../../../config/api";

export default function DeleteDialog({ handleClose, isPlan, id, name }) {
  const { mutate, isLoading } = useMutation({
    mutationFn: isPlan ? deletePlan : deleteVerifier,
  });
  const handleOnClick = async () => {
    mutate(id);
  };
  return (
    <Stack gap={3}>
      <Typography variant="h5" color="primary.main">
        {`Delete ${isPlan ? "Plan" : "Verifier"}`}
      </Typography>
      <Typography lineHeight={2}>
        {`By clicking delete, this ${
          isPlan ? "plan" : "verifier"
        } "${name}" would be removed. Do you want to
        continue?`}
      </Typography>
      <Stack flexDirection="row" gap={3} alignSelf="flex-end">
        <Button
          variant="contained"
          onClick={handleClose}
          color="grey"
          sx={{ color: "white" }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          color="error"
          onClick={handleOnClick}
        >
          Delete
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
