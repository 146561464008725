import { Box, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { AiOutlineEdit, AiOutlineEyeInvisible } from "react-icons/ai";
import { HiDocumentDuplicate } from "react-icons/hi";
import Dialog from "./Dialog/Dialog";
import HideEventDialog from "./Dialog/DialogContent/HideEventDialog";
import EventDetailsDialog from "./Dialog/DialogContent/EventDetailsDialog";
import { ReactComponent as ViewIcon } from "../assets/icons/view-icon.svg";
import DownloadGuestsList from "./DownloadGuestsList";
import placeholder from "../assets/images/logo.png";

export default function EventCell({
  url,
  name,
  date,
  time,
  place,
  id,
  host,
  isHidden,
}) {
  return (
    <>
      <Stack flexDirection="row" gap={5} pb={2} mt={2}>
        <Box
          component="img"
          src={url ?? placeholder}
          width="88px"
          height="85px"
          borderRadius={2}
          boxShadow={3}
          bgcolor="grey"
          sx={{
            objectFit: "cover",
          }}
          onError={(error) => {
            error.currentTarget.src = placeholder;
          }}
        />
        <Stack
          flexDirection="column"
          flexGrow={1}
          justifyContent="space-between"
          gap={3}
        >
          <Stack
            flexDirection="row"
            flexWrap="wrap"
            gap={2}
            justifyContent="space-between"
          >
            <Typography variant="body1" fontWeight={500}>
              {name}
            </Typography>

            <Typography variant="body1" fontWeight={500}>
              Date:{" "}
              <Box component="span" fontWeight={100}>
                {date}
              </Box>
            </Typography>

            <Typography variant="body1" fontWeight={500}>
              Time:{" "}
              <Box component="span" fontWeight={100}>
                {time}
              </Box>
            </Typography>

            <Typography variant="body1" fontWeight={500}>
              Venue:{" "}
              <Box component="span" fontWeight={100}>
                {place}
              </Box>
            </Typography>
            <Typography variant="body1" fontWeight={500}>
              Host:{" "}
              <Box component="span" fontWeight={100}>
                {host}
              </Box>
            </Typography>
          </Stack>
          <Stack
            alignSelf="flex-end"
            flexDirection="row"
            flexWrap="wrap"
            gap={{ xs: 1, lg: 5 }}
          >
            <DownloadGuestsList id={id} name={name} />

            <Dialog
              btnProps={{
                variant: "contained",
                endIcon: <AiOutlineEdit />,
              }}
              dialogContent={<EventDetailsDialog editMode id={id} />}
            >
              Edit
            </Dialog>

            <Tooltip
              title={
                // isHidden &&
                "To Control The Event Visibility Edit Its Dates"
              }
            >
              <span>
                <Dialog
                  btnProps={{
                    variant: "contained",
                    color: "grey",
                    sx: { color: "white" },
                    // disabled: isHidden,
                    disabled: true,
                    endIcon: <AiOutlineEyeInvisible />,
                  }}
                  dialogContent={<HideEventDialog id={id} />}
                  dialogMaxWidth={450}
                >
                  {isHidden ? "Show" : "Hide"}
                </Dialog>
              </span>
            </Tooltip>

            <Dialog
              btnProps={{ variant: "outlined", endIcon: <ViewIcon /> }}
              dialogContent={<EventDetailsDialog id={id} viewMode />}
            >
              View Details
            </Dialog>
            <Dialog
              btnProps={{
                variant: "contained",
                endIcon: <HiDocumentDuplicate />,
                color: "secondary",
              }}
              dialogContent={<EventDetailsDialog id={id} duplicateMode />}
            >
              Duplicate
            </Dialog>
          </Stack>
        </Stack>
      </Stack>
      <Divider
        sx={{
          borderColor: (theme) => theme.palette.primary.main,
          boxShadow: "0px 5px 4px rgba(0, 0, 0, 0.25);",
        }}
      />
    </>
  );
}
