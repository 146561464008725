import { CircularProgress, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { IoAddOutline } from "react-icons/io5";
import Dialog from "../components/Dialog/Dialog";
import AddEventPlan from "../components/Dialog/DialogContent/AddEventPlan";
import AddVerifierDialog from "../components/Dialog/DialogContent/AddVerifierDialog";
import ProviderRow from "../components/ProviderRow";
import { getProvidersWithDetails } from "../config/api";

export default function Providers() {
  const {
    data: providers,
    isLoading,
    isError,
  } = useQuery({
    staleTime: Infinity,
    queryKey: ["providers", "details"],
    queryFn: getProvidersWithDetails,
  });

  if (isLoading) {
    return <CircularProgress sx={{ display: "block", mx: "auto" }} />;
  }
  if (isError) {
    return <Typography> Somethig went wrong!</Typography>;
  }
  if (providers?.data?.length === 0)
    return <Typography>Sorry there is no data to display</Typography>;

  return (
    <Stack>
      <Stack
        flexDirection={{ md: "row" }}
        justifyContent="space-between"
        flexWrap="wrap"
        gap={2}
        mb={7}
      >
        <Dialog
          dialogMaxWidth={750}
          dialogContent={<AddEventPlan />}
          btnProps={{
            variant: "contained",
            sx: { px: 8, py: 1 },
            startIcon: <IoAddOutline color="white" />,
          }}
        >
          Add Event Plan
        </Dialog>
        <Dialog
          dialogContent={<AddVerifierDialog />}
          dialogMaxWidth={790}
          btnProps={{
            variant: "contained",
            sx: { px: 8, py: 1 },
            startIcon: <IoAddOutline color="white" />,
          }}
        >
          Add Verifier
        </Dialog>
      </Stack>
      {providers?.data?.map((provider) => (
        <ProviderRow
          key={provider?.id}
          provider={provider?.name}
          eventPlans={provider?.plans}
          verifiers={provider?.verifiers}
        />
      ))}
    </Stack>
  );
}
