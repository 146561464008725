import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import { getVerifier, postVerifier, updateVerifier } from "../../../config/api";
import { errorMsg } from "../../../config/util";
import useProvidersList from "../../../hooks/useProvidersList";

const validationSchema = (isEdit) => {
  return isEdit
    ? yup.object({
        provider: yup.string().required(errorMsg.required),
        title: yup.string().required(errorMsg.required),
        username: yup.string().required(errorMsg.required),
        displayName: yup.string().required(errorMsg.required),
        password: yup
          .string()
          .matches(
            RegExp("(?=.*[a-z])"),
            "Password should at least has one lowercase letter."
          )
          .matches(
            RegExp("(?=.*[A-Z])"),
            "Password should at least has one Uppercase letter."
          )
          .matches(
            RegExp("(?=.*[0-9])"),
            "Password should at least has one number."
          )
          .matches(
            RegExp("(?=.*[^A-Za-z0-9])"),
            "Password should at least has one special character."
          )
          .matches(
            RegExp("(?=.{8,})"),
            "Password should at least be eight characters long."
          ),
        confirmPassword: yup
          .string()
          .oneOf([yup.ref("password")], "Passwords must match."),
      })
    : yup.object({
        provider: yup.string().required(errorMsg.required),
        title: yup.string().required(errorMsg.required),
        username: yup.string().required(errorMsg.required),
        displayName: yup.string().required(errorMsg.required),
        password: yup
          .string()
          .required(errorMsg.required)
          .matches(
            RegExp("(?=.*[a-z])"),
            "Password should at least has one lowercase letter."
          )
          .matches(
            RegExp("(?=.*[A-Z])"),
            "Password should at least has one Uppercase letter."
          )
          .matches(
            RegExp("(?=.*[0-9])"),
            "Password should at least has one number."
          )
          .matches(
            RegExp("(?=.*[^A-Za-z0-9])"),
            "Password should at least has one special character."
          )
          .matches(
            RegExp("(?=.{8,})"),
            "Password should at least be eight characters long."
          ),
        confirmPassword: yup
          .string()
          .required(errorMsg.required)
          .oneOf([yup.ref("password")], "Passwords must match."),
      });
};

export default function AddVerifierDialog({ handleClose, userId }) {
  const { providers, providersIsLoading } = useProvidersList();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: !!userId ? updateVerifier : postVerifier,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["providers", "details"],
        exact: true,
      });
      toast.success(
        `The verifier has been successfully ${!!userId ? "edited" : "added"}`
      );
      handleClose();
    },
  });

  const onSubmit = ({ username, displayName, password, title, provider }) => {
    let data = {
      username,
      password,
      displayName,
      title,
      providerId: provider,
    };

    if (!!userId) data = { body: data, id: userId };

    mutate(data);
  };

  const formik = useFormik({
    initialValues: {
      provider: "",
      title: "",
      username: "",
      displayName: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema(!!userId),
    onSubmit,
  });

  const {
    isLoading: loadingDetails,
    isError,
    error,
  } = useQuery({
    enabled: !!userId,
    cacheTime: 0,
    staleTime: Infinity,
    queryKey: ["verifier", userId],
    queryFn: getVerifier.bind(this, userId),
    onSuccess: (verifierDetails) => {
      const values = {
        provider: verifierDetails?.data?.providerId,
        title: verifierDetails?.data?.title,
        username: verifierDetails?.data?.username,
        displayName: verifierDetails?.data?.displayName,
      };

      formik.setValues(values);
    },
  });

  if (!!userId && loadingDetails)
    return <CircularProgress sx={{ my: 5, display: "block", mx: "auto" }} />;
  if (!!userId && isError)
    return (
      <>
        <Typography mt={5} variant="h5">
          Error fetching data
        </Typography>
        <Typography mt={1} mb={5}>
          {error?.response?.data?.message}
        </Typography>
      </>
    );

  return (
    <Stack alignItems="center">
      <UserIcon />
      <Stack
        mt={10}
        width="100%"
        gap={{ xs: 5, md: 3 }}
        component="form"
        onSubmit={formik.handleSubmit}
      >
        <Stack flexDirection={{ sm: "row" }} gap={{ xs: 2, md: 5 }}>
          <Stack
            flexDirection="row"
            alignItems="center"
            width={{ md: "50%" }}
            justifyContent="space-between"
            flexGrow={{ xs: 1, md: 0 }}
          >
            <InputLabel sx={{ minWidth: "min-content", mr: 2 }}>
              Provider
            </InputLabel>
            <TextField
              id="provider"
              name="provider"
              variant="outlined"
              size="small"
              disabled={providersIsLoading || !!userId}
              select
              //fullwidth
              sx={{ width: "184px" }}
              value={formik.values.provider}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.provider && Boolean(formik.errors.provider)}
              helperText={formik.touched.provider && formik.errors.provider}
            >
              {providersIsLoading ? (
                <MenuItem key={0} value={0} disabled>
                  Loading...
                </MenuItem>
              ) : (
                providers?.map((provider) => (
                  <MenuItem key={provider?.id} value={provider?.id}>
                    {provider?.name}
                  </MenuItem>
                ))
              )}
            </TextField>
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="center"
            width={{ md: "50%" }}
            justifyContent="space-between"
            flexGrow={{ xs: 1, md: 0 }}
          >
            <InputLabel sx={{ minWidth: "min-content", mr: 2 }}>
              Username
            </InputLabel>
            <TextField
              id="username"
              name="username"
              variant="outlined"
              size="small"
              // fullWidth
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
          </Stack>
        </Stack>

        <Stack flexDirection={{ sm: "row" }} gap={{ xs: 2, md: 5 }}>
          <Stack
            flexDirection="row"
            alignItems="center"
            width={{ md: "50%" }}
            justifyContent="space-between"
            flexGrow={{ xs: 1, md: 0 }}
          >
            <InputLabel sx={{ minWidth: "min-content", mr: 2 }}>
              Title
            </InputLabel>
            <TextField
              id="title"
              name="title"
              variant="outlined"
              size="small"
              sx={{ width: "184px" }}
              // fullWidth
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="center"
            width={{ md: "50%" }}
            justifyContent="space-between"
            flexGrow={{ xs: 1, md: 0 }}
          >
            <InputLabel sx={{ minWidth: "min-content", mr: 2 }}>
              Display Name
            </InputLabel>
            <TextField
              id="displayName"
              name="displayName"
              variant="outlined"
              size="small"
              // fullWidth
              value={formik.values.displayName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.displayName && Boolean(formik.errors.displayName)
              }
              helperText={
                formik.touched.displayName && formik.errors.displayName
              }
            />
          </Stack>
        </Stack>
        {/* {!!!userId && ( */}
        <Stack flexDirection={{ sm: "row" }} gap={{ xs: 2, md: 5 }}>
          <Stack
            flexDirection="row"
            alignItems="center"
            width={{ md: "50%" }}
            justifyContent="space-between"
            flexGrow={{ xs: 1, md: 0 }}
          >
            <InputLabel sx={{ minWidth: "min-content", mr: 2 }}>
              Password
            </InputLabel>
            <TextField
              id="password"
              name="password"
              variant="outlined"
              size="small"
              // type="password"
              // fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="center"
            width={{ md: "50%" }}
            justifyContent="space-between"
            flexGrow={{ xs: 1, md: 0 }}
          >
            <InputLabel sx={{ minWidth: "min-content", mr: 2 }}>
              Confirm Password
            </InputLabel>
            <TextField
              id="confirmPassword"
              name="confirmPassword"
              variant="outlined"
              size="small"
              // type="password"
              // fullWidth
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
            />
          </Stack>
        </Stack>
        {/* )} */}

        <Stack flexDirection="row" gap={3} alignSelf="flex-end" mt={5}>
          <Button
            disabled={isLoading}
            color="grey"
            variant="contained"
            sx={{ color: "white " }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            type="submit"
            color="primary"
            variant="contained"
          >
            {!!userId ? "Edit" : "Create Verifier"}
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
}
