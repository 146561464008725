import { ToastContainer as ToastifyContainer } from "react-toastify";

export default function ToastContainer() {
  return (
    <ToastifyContainer
      autoClose={5000}
      hideProgressBar
      newestOnTop
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}
