import { IconButton, InputLabel, Stack, TextField } from "@mui/material";
import { IoCloseOutline } from "react-icons/io5";

export default function PlanTicketType({ formik, index }) {
  const removeTicketType = () => {
    const newTickets = formik.values.tickets.filter(
      (ticket) => ticket.id !== formik.values.tickets[index].id
    );
    formik.setFieldValue("tickets", newTickets);
  };

  return (
    <Stack flexDirection="row" alignItems="flex-start" mt={2}>
      <Stack
        name={`tickets.${index}`}
        flexDirection="row"
        gap={{ xs: 1, sm: 2 }}
        flexGrow={1}
      >
        <Stack gap={0.5}>
          <InputLabel>Ticket Type</InputLabel>
          <TextField
            id={`tickets.${index}.ticketType`}
            name={`tickets.${index}.ticketType`}
            variant="outlined"
            size="small"
            value={formik.values.tickets[index].ticketType}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              !!formik.touched.tickets &&
              !!formik.touched.tickets[index]?.ticketType &&
              !!formik.errors.tickets &&
              !!formik.errors.tickets[index]?.ticketType
            }
            helperText={
              !!formik.touched.tickets &&
              !!formik.touched.tickets[index]?.ticketType &&
              !!formik.errors.tickets &&
              formik.errors.tickets[index]?.ticketType
            }
          />
        </Stack>

        <Stack gap={0.5}>
          <InputLabel>Unit Price</InputLabel>
          <TextField
            id={`tickets.${index}.price`}
            name={`tickets.${index}.price`}
            variant="outlined"
            size="small"
            type="number"
            value={formik.values.tickets[index].price}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              !!formik.touched.tickets &&
              !!formik.touched.tickets[index]?.price &&
              !!formik.errors.tickets &&
              !!formik.errors.tickets[index]?.price
            }
            helperText={
              !!formik.touched.tickets &&
              !!formik.touched.tickets[index]?.price &&
              !!formik.errors.tickets &&
              formik.errors.tickets[index]?.price
            }
            InputProps={{
              endAdornment: "SDG",
            }}
          />
        </Stack>

        <Stack gap={0.5} flexGrow={1}>
          <InputLabel>Description</InputLabel>
          <TextField
            id={`tickets.${index}.description`}
            name={`tickets.${index}.description`}
            variant="outlined"
            size="small"
            multiline
            value={formik.values.tickets[index].description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              !!formik.touched.tickets &&
              !!formik.touched.tickets[index]?.description &&
              !!formik.errors.tickets &&
              !!formik.errors.tickets[index]?.description
            }
            helperText={
              !!formik.touched.tickets &&
              !!formik.touched.tickets[index]?.description &&
              !!formik.errors.tickets &&
              formik.errors.tickets[index]?.description
            }
          />
        </Stack>
      </Stack>
      {formik.values.tickets?.length > 1 && (
        <IconButton onClick={removeTicketType}>
          <IoCloseOutline color="grey" />
        </IconButton>
      )}
    </Stack>
  );
}
