import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { ReactComponent as DownloadIcon } from "../assets/icons/download-icon.svg";
import { getGuestList } from "../config/api";

export default function DownloadGuestsList({ id, name }) {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      const response = await getGuestList(id);
      const blob = new Blob([response?.data], {
        type: "file/xlsx",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `guest_list_${name
        ?.toLowerCase()
        ?.split(" ")
        ?.join("_")}.xlsx`;
      link.click();
    } catch (error) {}
    setLoading(false);
  };
  return (
    <LoadingButton
      loadingPosition="end"
      loading={loading}
      variant="outlined"
      color="primary"
      endIcon={<DownloadIcon />}
      onClick={handleClick}
    >
      Download Guest List
    </LoadingButton>
  );
}
