import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";

let theme = createTheme({
  palette: {
    primary: { main: "#46A348" },
    secondary: { main: "#FE7B54", contrastText: "white" },
    grey: { main: "#979797", contrastText: "white" },
    background: { main: "#F2F8F1" },
  },
});

theme = createTheme({
  ...theme,
  typography: {
    fontFamily: [
      "futura",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          textTransform: "none",
          paddingTop: 0.7,
          paddingBottom: 0.7,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.main,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          color: "black",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxHeight: "calc(100% - 10px)",
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export function ThemeProvider({ children }) {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
