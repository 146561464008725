import { Box, Typography } from "@mui/material";

export default function PageNotFound() {
  return (
    <Typography
      variant="h5"
      position="absolute"
      top="50%"
      left="50%"
      sx={{ transform: "translate(-50%, -50%)" }}
      whiteSpace="nowrap"
    >
      <Box component="span" fontWeight={500}>
        404
      </Box>{" "}
      | Page Not Found
    </Typography>
  );
}
